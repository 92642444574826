export default {
    "productHome.msg0":"Outdoor Decking Board",
    "productHome.msg1":"Indoor Plank",
    "productHome.msg2":"rPET",

    "productHome.outfloorHome.msg0":"E F Wood",
    "productHome.outfloorHome.msg1":"E F Wood",
    "productHome.outfloorHome.msg2":"Lite",
    "productHome.outfloorHome.msg3":"WPC second gen. ",
    "productHome.outfloorHome.msg4":"improved version",

    "productHome.outfloorHome.linoleum.msg0":"Introduce",
    "productHome.outfloorHome.linoleum.msg1":"\"E F Wood\"",
    "productHome.outfloorHome.linoleum.msg2":"Let recycle to be seemed",
    "productHome.outfloorHome.linoleum.msg3":"E F Wood uses used fabric and turn into wood-like boards.",
    "productHome.outfloorHome.linoleum.msg4":"Color will alter by each batches of used fabrics",
    "productHome.outfloorHome.linoleum.msg5":"You can see fabric on product cross-section",
    "productHome.outfloorHome.linoleum.msg6":"It is best for company CSR promotion uses.",
    "productHome.outfloorHome.linoleum.msg7":"Two R",
    "productHome.outfloorHome.linoleum.msg8":" Weather resistant, grand resistant ",
    "productHome.outfloorHome.linoleum.msg9":"Three P",
    "productHome.outfloorHome.linoleum.msg10":" Water Proof, Expansion Proof, Mold Proof ",
    "productHome.outfloorHome.linoleum.msg11":"Three A",
    "productHome.outfloorHome.linoleum.msg12":" Anti-scratch, Anti-stain, Anti-fade ",
    "productHome.outfloorHome.linoleum.msg13":"Cutting, Nailing",
    "productHome.outfloorHome.linoleum.msg14":"Drilling, Screwing",
    "productHome.outfloorHome.linoleum.msg15":"Just like Usual",
    "productHome.outfloorHome.linoleum.msg16":"Even after ten years",
    "productHome.outfloorHome.linoleum.msg17":"can remanufacture into new",
    "productHome.outfloorHome.linoleum.msg18":"No matter after five or ten years",
    "productHome.outfloorHome.linoleum.msg19":"E F Wood can still remanufacture",
    "productHome.outfloorHome.linoleum.msg20":"into brand new E F Wood",
    "productHome.outfloorHome.linoleum.msg21":"Two specification for your picking",
    "productHome.outfloorHome.linoleum.msg22":"Thickness 25mm/width 150mm",
    "productHome.outfloorHome.linoleum.msg23":"length 2200mm/ weight 8.1kg",
    "productHome.outfloorHome.linoleum.msg24":"Thickness 30mm/ wide 180mm",
    "productHome.outfloorHome.linoleum.msg25":"length 2200mm/weight 10.8kg",
    "productHome.outfloorHome.linoleum.msg26":"EDM",

    "productHome.outfloorHome.linoleumLite.msg0":"E F Wood Lite",
    "productHome.outfloorHome.linoleumLite.msg1":"\"Light than Before.\"",
    "productHome.outfloorHome.linoleumLite.msg2":"35%",
    "productHome.outfloorHome.linoleumLite.msg3":"Compare to E F Wood weight reduced 35%, but as sturdy",
    "productHome.outfloorHome.linoleumLite.msg4":"Color",
    "productHome.outfloorHome.linoleumLite.msg5":"Not only we reduce product weight",
    "productHome.outfloorHome.linoleumLite.msg6":"We put a coding on outer layer",
    "productHome.outfloorHome.linoleumLite.msg7":"to enable color selection",
    "productHome.outfloorHome.linoleumLite.msg8":"8 advantage still remains",
    "productHome.outfloorHome.linoleumLite.msg9":" Water Proof, Expansion Proof, Mold Proof ",
    "productHome.outfloorHome.linoleumLite.msg10":" Weather resistant, grand resistant ",
    "productHome.outfloorHome.linoleumLite.msg11":" Anti-scratch, Anti-stain, Anti-fade ",
    "productHome.outfloorHome.linoleumLite.msg12":"Cutting, Nailing, Drilling, Screwing",
    "productHome.outfloorHome.linoleumLite.msg13":"All possible!",
    "productHome.outfloorHome.linoleumLite.msg14":"Ten years later, can recycle and remake into new",
    "productHome.outfloorHome.linoleumLite.msg15":"Ten years later, even remake still as sturdy as before",

    "productHome.outfloorHome.wpc.msg0":"WPC+Strong and Wise = more possiblities",
    "productHome.outfloorHome.wpc.msg1":"WPC second generation \"improved version\"",
    "productHome.outfloorHome.wpc.msg2":"Exclusive patented tech. outer layer",
    "productHome.outfloorHome.wpc.msg3":"Strong and Wise based on already improved second gen. WPC board",
    "productHome.outfloorHome.wpc.msg4":"Added our own patented outer layer",
    "productHome.outfloorHome.wpc.msg5":"improved WPC’s pain points",
    "productHome.outfloorHome.wpc.msg6":"No more deformation, expansion",
    "productHome.outfloorHome.wpc.msg7":"when warp in our exclusive patented skin layer",
    "productHome.outfloorHome.wpc.msg8":"WPC board will decrease its water absorption rate.",
    "productHome.outfloorHome.wpc.msg9":"Increase product life spent, decrease damage rate",
    "productHome.outfloorHome.wpc.msg10":"Cutting, Nailing, Drilling, Screwing",
    "productHome.outfloorHome.wpc.msg11":"All possible!",
    "productHome.outfloorHome.wpc.msg12":"One Size",
    "productHome.outfloorHome.wpc.msg13":"Thickness 24mm/width 140mm",
    "productHome.outfloorHome.wpc.msg14":"length 2200mm/weight 6.3kg",
    "productHome.outfloorHome.wpc.msg15":"Other more sturdy and more eco-friendly choices",
    "productHome.outfloorHome.wpc.msg16":"E F Wood",
    "productHome.outfloorHome.wpc.msg17":"E F Wood Lite",

    "productHome.linoleumIndoor.msg0":"E F Wood indoor plank",
    "productHome.linoleumIndoor.msg1":"Brings eco-friendly into your home",
    "productHome.linoleumIndoor.msg2":"more specifications, for more choices",
    "productHome.linoleumIndoor.msg3":"renovate, color, 8 advantage, all combine together",
    "productHome.linoleumIndoor.msg4":"E F Wood indoor plank not only can cut, nail, drill, screw, can even go according to your desire \
                                        color. E F Wood indoor plank not only can weather resistant, grand resistant, water proof, \
                                        expansion proof, mold proof, anti-scratch, anti-stain, anti-fade, more importantly it is eco-friendly\
                                        and able to remanufacture into new even after ten years of use.",
    "productHome.linoleumIndoor.msg5":"Meet us , in your house",
    "productHome.linoleumIndoor.img0":"/images/Products/Linoleum/enFoamProductInformation.png",
    "productHome.linoleumIndoor.img1":"/images/Products/Linoleum/enNonFoamingProductInformation.png",

    "productHome.SingleMaterial.msg0":"No more garments can’t fully recycle issue",
    "productHome.SingleMaterial.msg1":"3 common components on garment or bag",
    "productHome.SingleMaterial.msg2":"buttons",
    "productHome.SingleMaterial.msg3":"zipper",
    "productHome.SingleMaterial.msg4":"buckle",
    "productHome.SingleMaterial.msg5":"textile part of clothing can be recycle",
    "productHome.SingleMaterial.msg6":"but needs to remove these components",
    "productHome.SingleMaterial.msg7":"due to the material difference",
    "productHome.SingleMaterial.msg8":"this increase garment recycling difficulty and cost",
    "productHome.SingleMaterial.msg9":"With single material garment or bag,",
    "productHome.SingleMaterial.msg10":"everything is much simpler",
    "productHome.SingleMaterial.msg11":"when use rPET as raw material",
    "productHome.SingleMaterial.msg12":"it can manufacture into all three components",
    "productHome.SingleMaterial.msg13":"has all different colors to choose",
    "productHome.SingleMaterial.msg14":"sustainability can be seem everywhere",
    "productHome.SingleMaterial.msg15":"after recycle single material garment",
    "productHome.SingleMaterial.msg16":"can remanufacture into E F Wood board",

}