export default {
    "productHome.msg0":"戶外地板",
    "productHome.msg1":"衣纖木板材",
    "productHome.msg2":"單一材質(rPET)",

    "productHome.outfloorHome.msg0":"衣纖木",
    "productHome.outfloorHome.msg1":"衣纖木",
    "productHome.outfloorHome.msg2":"Lite",
    "productHome.outfloorHome.msg3":"WPC二代",
    "productHome.outfloorHome.msg4":"改良版",

    "productHome.outfloorHome.linoleum.msg0":"隆重介紹",
    "productHome.outfloorHome.linoleum.msg1":"\"衣纖木\"",
    "productHome.outfloorHome.linoleum.msg2":"環保看得見",
    "productHome.outfloorHome.linoleum.msg3":"衣纖木是用衣服纖維再製而成的板材",
    "productHome.outfloorHome.linoleum.msg4":"顏色也會隨著回收的衣料變化",
    "productHome.outfloorHome.linoleum.msg5":"橫切面有著衣服的纖維 讓環保被看見",
    "productHome.outfloorHome.linoleum.msg6":"最適合為企業CSR宣導",
    "productHome.outfloorHome.linoleum.msg7":"二耐",
    "productHome.outfloorHome.linoleum.msg8":" 耐候 耐磨 ",
    "productHome.outfloorHome.linoleum.msg9":"三不",
    "productHome.outfloorHome.linoleum.msg10":" 不吸水 不膨脹 不發霉 ",
    "productHome.outfloorHome.linoleum.msg11":"三抗",
    "productHome.outfloorHome.linoleum.msg12":" 抗括 抗污 抗褪色 ",
    "productHome.outfloorHome.linoleum.msg13":"切割 打釘子",
    "productHome.outfloorHome.linoleum.msg14":"鑽孔 擰螺絲",
    "productHome.outfloorHome.linoleum.msg15":"就如往常一樣",
    "productHome.outfloorHome.linoleum.msg16":"過了十年",
    "productHome.outfloorHome.linoleum.msg17":"環保依舊",
    "productHome.outfloorHome.linoleum.msg18":"不管過了五年十年",
    "productHome.outfloorHome.linoleum.msg19":"衣纖木可以重新回收",
    "productHome.outfloorHome.linoleum.msg20":"再 生成新的衣纖木",
    "productHome.outfloorHome.linoleum.msg21":"兩種尺寸,任君挑選",
    "productHome.outfloorHome.linoleum.msg22":"厚 25nm/寬150mm",
    "productHome.outfloorHome.linoleum.msg23":"長 2200mm/重 8.1kg",
    "productHome.outfloorHome.linoleum.msg24":"厚 30mm/寬180mm",
    "productHome.outfloorHome.linoleum.msg25":"長 2200mm/重 10.8kg",
    "productHome.outfloorHome.linoleum.msg26":"產品型錄",

    "productHome.outfloorHome.linoleumLite.msg0":"衣纖木Lite",
    "productHome.outfloorHome.linoleumLite.msg1":"\"輕量化\"",
    "productHome.outfloorHome.linoleumLite.msg2":"35%",
    "productHome.outfloorHome.linoleumLite.msg3":"相較於衣纖木,Lite降低了35%的重量,承受的重量幾乎一樣",
    "productHome.outfloorHome.linoleumLite.msg4":"色彩",
    "productHome.outfloorHome.linoleumLite.msg5":"我們輕量化的同時",
    "productHome.outfloorHome.linoleumLite.msg6":"在外麵包覆了一層皮層",
    "productHome.outfloorHome.linoleumLite.msg7":"讓衣纖木可以有更多色彩上的變化",
    "productHome.outfloorHome.linoleumLite.msg8":"八大優點，一個不缺",
    "productHome.outfloorHome.linoleumLite.msg9":" 不吸水 不膨脹 不發霉 ",
    "productHome.outfloorHome.linoleumLite.msg10":" 耐候 耐磨 ",
    "productHome.outfloorHome.linoleumLite.msg11":" 抗括 抗污 抗褪色 ",
    "productHome.outfloorHome.linoleumLite.msg12":"切、打、鑽、擰",
    "productHome.outfloorHome.linoleumLite.msg13":"樣樣行！",
    "productHome.outfloorHome.linoleumLite.msg14":"十年之後,你還能見到我",
    "productHome.outfloorHome.linoleumLite.msg15":"十年之後,可以把使用過的衣纖木重新回收煥然一新的回到你的身邊",

    "productHome.outfloorHome.wpc.msg0":"WPC+雄材大智 = 更多可能",
    "productHome.outfloorHome.wpc.msg1":"WPC二代木\"改良板\"",
    "productHome.outfloorHome.wpc.msg2":"獨家專利皮層",
    "productHome.outfloorHome.wpc.msg3":"雄材大智在傳統的WPC二代木上",
    "productHome.outfloorHome.wpc.msg4":"增加了自己的專利PET皮層",
    "productHome.outfloorHome.wpc.msg5":"改善了過去WPC的缺點",
    "productHome.outfloorHome.wpc.msg6":"膨脹、變形、脆化，再也不見",
    "productHome.outfloorHome.wpc.msg7":"專利皮層包裹後，",
    "productHome.outfloorHome.wpc.msg8":"過往WPC碰到水會膨脹、變形、脆化的狀況會大幅改善",
    "productHome.outfloorHome.wpc.msg9":"延長使用年限，降低損壞機率",
    "productHome.outfloorHome.wpc.msg10":"切、打、鑽、擰",
    "productHome.outfloorHome.wpc.msg11":"樣樣行!",
    "productHome.outfloorHome.wpc.msg12":"One Size",
    "productHome.outfloorHome.wpc.msg13":"厚 24nm/寬140mm",
    "productHome.outfloorHome.wpc.msg14":"長 2200mm/重 6.3kg",
    "productHome.outfloorHome.wpc.msg15":"其它更加堅固環保的選擇",
    "productHome.outfloorHome.wpc.msg16":"衣纖木",
    "productHome.outfloorHome.wpc.msg17":"衣纖木Lite",

    "productHome.linoleumIndoor.msg0":"衣纖木室內板材",
    "productHome.linoleumIndoor.msg1":"讓環保，進駐家中",
    "productHome.linoleumIndoor.msg2":"更多尺寸，讓你有更多選擇",
    "productHome.linoleumIndoor.msg3":"裝修、色彩、八大優點，缺一不可",
    "productHome.linoleumIndoor.msg4":"衣纖木室內板材不僅可以切割、打釘、鑽孔、擰螺絲還可以依照您的室內設計風格客制需要的顏色外層不吸水、膨脹、發霉，耐候、耐磨，抗刮、污、褪色最重要的是，依然環保，依然能無限回收再製成新品",
    "productHome.linoleumIndoor.msg5":"期待與你，家中相見",
    "productHome.linoleumIndoor.img0":"/images/Products/Linoleum/twFoamProductInformation.png",
    "productHome.linoleumIndoor.img1":"/images/Products/Linoleum/twNonFoamingProductInformation.png",

    "productHome.SingleMaterial.msg0":"讓我們解決衣服不能完全回收的問題",
    "productHome.SingleMaterial.msg1":"衣服上常見的三配件",
    "productHome.SingleMaterial.msg2":"鈕扣",
    "productHome.SingleMaterial.msg3":"拉鍊",
    "productHome.SingleMaterial.msg4":"扣具",
    "productHome.SingleMaterial.msg5":"環保衣服的材質可以回收",
    "productHome.SingleMaterial.msg6":"但三配件通常無法一起",
    "productHome.SingleMaterial.msg7":"三配件也因為材質不同",
    "productHome.SingleMaterial.msg8":"造成了回收衣物的難度增加",
    "productHome.SingleMaterial.msg9":"當我們統一材質",
    "productHome.SingleMaterial.msg10":"一切變得簡單了起來",
    "productHome.SingleMaterial.msg11":"使用rPET作為原料",
    "productHome.SingleMaterial.msg12":"可以製成環保的三配件",
    "productHome.SingleMaterial.msg13":"也可以自由調色與衣服做搭配",
    "productHome.SingleMaterial.msg14":"永續經營,晚點見",
    "productHome.SingleMaterial.msg15":"使用rPET製成的單一材質產品",
    "productHome.SingleMaterial.msg16":"在使用過後可以重新製造成衣纖木地板",

}