export default {
    "SkillSupport.qanda": "Q&As",
    "SkillSupport.installation_notes": "Installation Notes",
    "SkillSupport.test_report": "Test Report",
    "QandA.title_1":"Does your products has test reports？",
    "SkillSupport.image": "/images/skillsupport/enWelcomeToTechnicalSupport.png",
    "QandA.image": "/images/skillsupport/enQ&A.png",
    "Installation_Notes.image": "/images/skillsupport/enInstallationInstructions.png",
    "Test_Report.image": "/images/skillsupport/enProductTestReport.png",
    "Test_Report.image_RWD": "/images/skillsupport/enProductTestReport.png",
    "QandA.content_1":"E F Wood has been tested by Taiwan SGS, also by china national center for quality supervision and inspection of building decoration materials.",
    "QandA.content_2":"Reports can be found in our website under tech.",
    "QandA.Link":"support\\product test reports",
    "QandA.title_2":"When under sun exposure, will it cost embrittlement or deformation problems?",
    "QandA.content_3":"E F Wood is design with long lift spent in mind.",
    "QandA.content_4":"Safe, non-toxic, and eco-friendly is our basic requirement.  sturdy, easy to clean, weather resist are also our priorities.",
    "QandA.content_5":"E F Wood has been tested under 2000 hours of UV weather resist test.",
    "QandA.title_3":"Does E F Wood need to put on protection oil？",
    "QandA.content_6":"E F Wood has superior grand resistant, anti-scratch, anti-stain, anti-fade and weather resistant.",
    "QandA.content_7":"Just some simple day to day cleaning can keep your deck as good as new.",
    "QandA.title_4":"Willing E F Wood be molding on the surface？",
    "QandA.content_8":"E F Wood un-like WPC contains wood dust, therefore mold can’t grow from inside.",
    "QandA.content_9":"Just a scheduled water rinse needed.",
    "QandA.title_5":"Is E F Wood easily damage？",
    "QandA.content_10":"E F Wood does not absorb water nor expand, therefore sturdy and structure wise is guaranty.",
    "QandA.title_6":"Can E F Wood be modified like normal hard wood?",
    "QandA.content_11":"E F Wood can be cut, nail, drill, and screw just like regular hard wood board.",
    "QandA.title_7":"How to clean E F Wood？",
    "QandA.content_12":"Can directly use pressurized water to rinse.",
    "QandA.title_8":"Can E F Wood be install at hot climate or cold climate?",
    "QandA.content_13":"E F Wood’s softening temperatures are up to 123 degree C, normally sun exposure temperatures are up to 70 degree C.",
    "QandA.content_14":"Also our product has been boil and freeze recurring test result : no filature, no bubble, size differ under 0.5mm,",
    "QandA.content_15":"and can withstand thawing and freezing up to 94% intact.",
    "QandA.title_9":"What is the life spent of E F Wood？",
    "QandA.content_16":"Under normal usage has very long life spent,",
    "QandA.content_17":"even after five or ten years of use if wanted to have color change.",
    "QandA.content_18":"can remanufacture into different color with just installing fee and processing fee added.",
    "QandA.title_10":"Can use old framework be use for installing E F Wood?",
    "QandA.content_19":"Need professional assessment to insure the framework’s condition.",
    "Test_Report.title_1":"E F Wood",
    "Test_Report.title_2":"E F Wood Lite",
    "Test_Report.title_3":"WPC Second-generation improved wood",
    "Test_Report.content_1":"台灣綠建材之放射性檢測報告",
    "Test_Report.content_2":"重金屬檢測報告",
    "Test_Report.content_3":"仪鸿检测 衣纤木 耐候-11020107",
    "Test_Report.content_4":"無石錦驗出-檢驗報告",
    "Test_Report.content_5":"台灣塑木規範-材料物性報告",
    "Test_Report.content_6":"無甲醛(voc)檢驗報告",
    "Test_Report.content_7":"(國家標準)中國國家建築裝修材質質量檢驗報告",
    "Test_Report.content_8":"皮層物性檢測報告",
    "Installation_Notes.title":"in maintenance",
    "Installation_Notes.img0":"/images/skillsupport/Installation_Notes/enInstallation_Notes.jpg",
}