export default {
    "productHome.msg0":"户外地板",
    "productHome.msg1":"衣纤木板材",
    "productHome.msg2":"单一材质(rPET)",

    "productHome.outfloorHome.msg0":"衣纤木",
    "productHome.outfloorHome.msg1":"衣纤木",
    "productHome.outfloorHome.msg2":"Lite",
    "productHome.outfloorHome.msg3":"WPC二代",
    "productHome.outfloorHome.msg4":"改良版",

    "productHome.outfloorHome.linoleum.msg0":"隆重介绍",
    "productHome.outfloorHome.linoleum.msg1":"\"衣纤木\"",
    "productHome.outfloorHome.linoleum.msg2":"环保看得见",
    "productHome.outfloorHome.linoleum.msg3":"衣纤木是用衣服纤维再制而成的板材",
    "productHome.outfloorHome.linoleum.msg4":"颜色也会随着回收的衣料变化",
    "productHome.outfloorHome.linoleum.msg5":"横切面有着衣服的纤维 让环保被看见",
    "productHome.outfloorHome.linoleum.msg6":"最适合为企业CSR宣导",
    "productHome.outfloorHome.linoleum.msg7":"二耐",
    "productHome.outfloorHome.linoleum.msg8":" 耐候 耐磨 ",
    "productHome.outfloorHome.linoleum.msg9":"三不",
    "productHome.outfloorHome.linoleum.msg10":" 不吸水 不膨胀 不发霉 ",
    "productHome.outfloorHome.linoleum.msg11":"三抗",
    "productHome.outfloorHome.linoleum.msg12":" 抗括 抗污 抗褪色 ",
    "productHome.outfloorHome.linoleum.msg13":"切割 打钉子",
    "productHome.outfloorHome.linoleum.msg14":"钻孔 拧螺丝",
    "productHome.outfloorHome.linoleum.msg15":"就如往常一样",
    "productHome.outfloorHome.linoleum.msg16":"过了十年",
    "productHome.outfloorHome.linoleum.msg17":"环保依旧",
    "productHome.outfloorHome.linoleum.msg18":"不管过了五年十年",
    "productHome.outfloorHome.linoleum.msg19":"衣纤木可以重新回收",
    "productHome.outfloorHome.linoleum.msg20":"再 生成新的衣纤木",
    "productHome.outfloorHome.linoleum.msg21":"两种尺寸,任君挑选",
    "productHome.outfloorHome.linoleum.msg22":"厚 25nm/宽150mm",
    "productHome.outfloorHome.linoleum.msg23":"长 2200mm/重 8.1kg",
    "productHome.outfloorHome.linoleum.msg24":"厚 30mm/宽180mm",
    "productHome.outfloorHome.linoleum.msg25":"长 2200mm/重 10.8kg",
    "productHome.outfloorHome.linoleum.msg26":"产品型录",

    "productHome.outfloorHome.linoleumLite.msg0":"衣纤木Lite",
    "productHome.outfloorHome.linoleumLite.msg1":"\"轻量化\"",
    "productHome.outfloorHome.linoleumLite.msg2":"35%",
    "productHome.outfloorHome.linoleumLite.msg3":"相较于衣纤木,Lite降低了35%的重量,承受的重量几乎一样",
    "productHome.outfloorHome.linoleumLite.msg4":"色彩",
    "productHome.outfloorHome.linoleumLite.msg5":"我们轻量化的同时",
    "productHome.outfloorHome.linoleumLite.msg6":"在外面包覆了一层皮层",
    "productHome.outfloorHome.linoleumLite.msg7":"让衣纤木可以有更多色彩上的变化",
    "productHome.outfloorHome.linoleumLite.msg8":"八大优点，一个不缺",
    "productHome.outfloorHome.linoleumLite.msg9":" 不吸水 不膨胀 不发霉 ",
    "productHome.outfloorHome.linoleumLite.msg10":" 耐候 耐磨 ",
    "productHome.outfloorHome.linoleumLite.msg11":" 抗括 抗污 抗褪色 ",
    "productHome.outfloorHome.linoleumLite.msg12":"切、打、钻、拧",
    "productHome.outfloorHome.linoleumLite.msg13":"样样行！",
    "productHome.outfloorHome.linoleumLite.msg14":"十年之后,你还能见到我",
    "productHome.outfloorHome.linoleumLite.msg15":"十年之后,可以把使用过的衣纤木重新回收焕然一新的回到你的身边",

    "productHome.outfloorHome.wpc.msg0":"WPC+雄材大智 = 更多可能",
    "productHome.outfloorHome.wpc.msg1":"WPC二代木\"改良板\"",
    "productHome.outfloorHome.wpc.msg2":"独家专利皮层",
    "productHome.outfloorHome.wpc.msg3":"雄材大智在传统的WPC二代木上",
    "productHome.outfloorHome.wpc.msg4":"增加了自己的专利PET皮层",
    "productHome.outfloorHome.wpc.msg5":"改善了过去WPC的缺点",
    "productHome.outfloorHome.wpc.msg6":"膨胀、变形、脆化，再也不见",
    "productHome.outfloorHome.wpc.msg7":"专利皮层包裹后，",
    "productHome.outfloorHome.wpc.msg8":"过往WPC碰到水会膨胀、变形、脆化的状况会大幅改善",
    "productHome.outfloorHome.wpc.msg9":"延长使用年限，降低损坏机率",
    "productHome.outfloorHome.wpc.msg10":"切、打、钻、拧",
    "productHome.outfloorHome.wpc.msg11":"样样行!",
    "productHome.outfloorHome.wpc.msg12":"One Size",
    "productHome.outfloorHome.wpc.msg13":"厚 24nm/宽140mm",
    "productHome.outfloorHome.wpc.msg14":"长 2200mm/重 6.3kg",
    "productHome.outfloorHome.wpc.msg15":"其它更加坚固环保的选择",
    "productHome.outfloorHome.wpc.msg16":"衣纤木",
    "productHome.outfloorHome.wpc.msg17":"衣纤木Lite",

    "productHome.linoleumIndoor.msg0":"衣纤木室内板材",
    "productHome.linoleumIndoor.msg1":"让环保，进驻家中",
    "productHome.linoleumIndoor.msg2":"更多尺寸，让你有更多选择",
    "productHome.linoleumIndoor.msg3":"装修、色彩、八大优点，缺一不可",
    "productHome.linoleumIndoor.msg4":"衣纤木室内板材不仅可以切割、打钉、钻孔、拧螺丝还可以依照您的室内设计风格客制需要的颜色外层不吸水、膨胀、发霉，耐候、耐磨，抗刮、污、褪色最重要的是，依然环保，依然能无限回收再制成新品",
    "productHome.linoleumIndoor.msg5":"期待与你，家中相见",
    "productHome.linoleumIndoor.img0":"/images/Products/Linoleum/FoamProductInformation.png",
    "productHome.linoleumIndoor.img1":"/images/Products/Linoleum/NonFoamingProductInformation.png",

    "productHome.SingleMaterial.msg0":"让我们解决衣服不能完全回收的问题",
    "productHome.SingleMaterial.msg1":"衣服上常见的三配件",
    "productHome.SingleMaterial.msg2":"钮扣",
    "productHome.SingleMaterial.msg3":"拉链",
    "productHome.SingleMaterial.msg4":"扣具",
    "productHome.SingleMaterial.msg5":"环保衣服的材质可以回收",
    "productHome.SingleMaterial.msg6":"但三配件通常无法一起",
    "productHome.SingleMaterial.msg7":"三配件也因为材质不同",
    "productHome.SingleMaterial.msg8":"造成了回收衣物的难度增加",
    "productHome.SingleMaterial.msg9":"当我们统一材质",
    "productHome.SingleMaterial.msg10":"一切变得简单了起来",
    "productHome.SingleMaterial.msg11":"使用rPET作为原料",
    "productHome.SingleMaterial.msg12":"可以制成环保的三配件",
    "productHome.SingleMaterial.msg13":"也可以自由调色与衣服做搭配",
    "productHome.SingleMaterial.msg14":"永续经营,晚点见",
    "productHome.SingleMaterial.msg15":"使用rPET制成的单一材质产品",
    "productHome.SingleMaterial.msg16":"在使用过后可以重新制造成衣纤木地板",

}