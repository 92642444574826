export default {

    "report.msg0":"新闻",
    "report.msg1":"奖项",
    "report.msg2":"活动",

    "report.news.msg0": "非凡新闻-台湾真善美-访谈",
    "report.news.msg1": "大老板联盟-节目片段",
   /*  "report.news.msg2": "金鱼脑-Youtuber访谈", */
    "report.news.msg3": "消失的国界",
    "report.news.msg4": "创业家请上车-节目片段",

    "report.awards.msg0": "青年创新创业大赛",
    "report.awards.msg1": "创业大赛",
    "report.awards.msg2": "雄材大智材料科技-Sam版本final",
    "report.awards.msg3": "潮客大赛",
    "report.awards.msg4": "潮客大赛2",

    "report.events.msg0": "太平岛",
    "report.events.msg1": "2020循环经济展",

}