export default {
    "SkillSupport.qanda": "问与答",
    "SkillSupport.installation_notes": "安装说明",
    "SkillSupport.test_report": "产品测试报告",
    "SkillSupport.image": "/images/skillsupport/WelcomeToTechnicalSupport.png",
    "QandA.image": "/images/skillsupport/QA.png",
    "Installation_Notes.image": "/images/skillsupport/InstallationInstructions.png",
    "Test_Report.image": "/images/skillsupport/ProductTestReport.png",
    "Test_Report.image_RWD": "/images/skillsupport/ProductTestReportbig.png",
    "QandA.title_1":"产品有测试报告吗？",
    "QandA.content_1":"衣纤木有经过台湾SGS检测，同时有通过中国国家建筑修材料质量监督检验中心的测试。",
    "QandA.content_2":"相关检测报告可在网站中",
    "QandA.Link":"技术支援\\产品测试报告內。",
    "QandA.title_2":"是否在开放式阳台的日晒雨淋下，有脆化及变形问题呢？",
    "QandA.content_3":"衣纤木是以长期使用为前提而开發出来的产品",
    "QandA.content_4":"安全、健康、环保是我们产品的基本要求，耐用好维护、长期抗候性更是我们的考量重点。",
    "QandA.content_5":"我们的产品在2000小时UV耐候试验下，外观无龟裂、粉化！",
    "QandA.title_3":"真的完全不用上油保养？",
    "QandA.content_6":"衣纤木具有优良的耐磨、抗刮、抗汙、抗褪色和耐候性能。",
    "QandA.content_7":"不用花时间保养，日常简单的清洁，就能让您的地板常保如新。",
    "QandA.title_4":"塑木地板的表面会發霉吗？",
    "QandA.content_8":"衣纤木因不像一般木粉塑木地板含有木粉，所以并不会从内部长霉。",
    "QandA.content_9":"只需定期用清水冲洗表面即可。",
    "QandA.title_5":"是否容易损坏吗？",
    "QandA.content_10":"衣纤木不吸水，不膨胀所以结构跟强度有保障。",
    "QandA.title_6":"能像木材一样加工吗？",
    "QandA.content_11":"衣纤木能像木材一样使用平常的木工工具进行切割、打钉子、鑽孔、拧螺钉等各种加工。",
    "QandA.title_7":"地板如何做日常清洁管理？",
    "QandA.content_12":"可直接用高压清水冲洗。",
    "QandA.title_8":"在太阳光很强的炎热环境和冰天雪地的低温环境下能否安装使用？",
    "QandA.content_13":"衣纤木的卫氏软化温度为123C一般来说太阳光照射最高只会到达70C。",
    "QandA.content_14":"同时经过国家检测耐冷热循环测试“无龟裂、无鼓泡”，尺寸变化小于等于0.5mm，",
    "QandA.content_15":"同时抗冻融性达到94%完好。",
    "QandA.title_9":"可以使用多久？",
    "QandA.content_16":"衣纤木在正常的使用状况下可使用很长的时间，",
    "QandA.content_17":"即便经过了5年10年对于颜色上想要有变化，",
    "QandA.content_18":"我们的产品可以回收重新再造成新颜色的地板只需提供相对应的施工费与加工费。",
    "QandA.title_10":"可以使用原先的骨架吗?",
    "QandA.content_19":"需请专人评估后才能确认是否能使用原先的骨架。",
    "Test_Report.title_1":"衣纤木",
    "Test_Report.title_2":"衣纤木Lite",
    "Test_Report.title_3":"WPC二代改良木",
    "Test_Report.content_1":"台灣綠建材之放射性檢測報告",
    "Test_Report.content_2":"重金屬檢測報告",
    "Test_Report.content_3":"仪鸿检测 衣纤木 耐候-11020107",
    "Test_Report.content_4":"無石錦驗出-檢驗報告",
    "Test_Report.content_5":"台灣塑木規範-材料物性報告",
    "Test_Report.content_6":"無甲醛(voc)檢驗報告",
    "Test_Report.content_7":"(國家標準)中國國家建築裝修材質質量檢驗報告",
    "Test_Report.content_8":"皮層物性檢測報告",
    "Installation_Notes.title":"维修中",
    "Installation_Notes.img0":"/images/skillsupport/Installation_Notes/Installation_Notes.jpg",
}