export default {

    "report.msg0":"新聞",
    "report.msg1":"獎項",
    "report.msg2":"活動",

    "report.news.msg0": "非凡新聞-台灣真善美-訪談",
    "report.news.msg1": "大老闆聯盟-節目片段",
    // "report.news.msg2": "金魚腦-Youtuber訪談",
    "report.news.msg3": "消失的國界",
    "report.news.msg4": "創業家請上車-節目片段",

    "report.awards.msg0": "青年創新創業大賽",
    "report.awards.msg1": "創業大賽",
    "report.awards.msg2": "雄材大智材料科技-Sam版本final",
    "report.awards.msg3": "潮客大賽",
    "report.awards.msg4": "潮客大賽2",

    "report.events.msg0": "太平島",
    "report.events.msg1": "2020循環經濟展",

}