export default {

    "report.msg0":"News",
    "report.msg1":"Awards",
    "report.msg2":"Events",

    "report.news.msg0": "USTV-interview",
    "report.news.msg1": "大老板联盟-节目片段",
    // "report.news.msg2": "金鱼脑-Youtuber访谈",
    "report.news.msg3": "消失的国界",
    "report.news.msg4": "创业加请上车-节目片段",

    "report.awards.msg0": "2019 Taipei 2nd Taiwan innovation and entrepreneurship competition championship",
    "report.awards.msg1": "2019 8th china innovation and entrepreneurship competition, hk,macao, taiwan division 3rd place",
    "report.awards.msg2": "Strong and wise-Sam version final",
    "report.awards.msg3": "China SZ 4th innovation and entrepreneurship competition silver award",
    "report.awards.msg4": "China SZ 4th innovation and entrepreneurship competition silver award 2",

    "report.events.msg0": "Taiping Island",
    "report.events.msg1": "2020 Circular Economy Exhibition",

}